<template>
  <div>
    <Header role="user" />
    <div class="d-flex" id="wrapper">
      <Sidebar role="user" />
      <div class="signupBgColour" id="page-content-wrapper">
        <div class="row">
          <div class="col-lg-11 col-12 userOutline">
            <div class="text w-100">
              <!-- <div class="d-block d-lg-none m-2">
                <div class="userMobileindexHeader">

                  <div class="searchInputBox">
                    <input class="form-control" type="search" name="search" placeholder="Search">
                  </div>
                </div>
              </div> -->
              <router-view />                                           
            </div>
          </div>
          <div class="col-lg-4 d-lg-block d-none">
            <MessageList />     
          </div>
        </div>


      </div>
    </div>
    <div class="d-block d-lg-none"
      v-if="!['Doctor-New-Message', 'Doctor-Message-Chat', 'Doctor-Comment'].includes($route.name)">
      <Footer role="user"></Footer>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Sidebar from '../layouts/Sidebar.vue'
import Header from '../layouts/Header.vue'
import Footer from '../layouts/CommonFooter.vue'
import MessageList from '../../views/common/messagelist.vue'
import '../../css/app.css'
export default {

  name: 'app',
  components: {
    Sidebar,
    Header,
    Footer,
    MessageList,
  },
  data() {
return {
  publicUserID:'',
  publicUniqueID:'',
  isVisible: true
}},

created: async function () {
  await this.getPublic();
},
mounted() {
    this.getPublic();
    document.addEventListener('visibilitychange', this.handleVisibilityChange);
    window.addEventListener('focus', this.handlePageFocus);
  },
  unmounted() {
    document.removeEventListener('visibilitychange', this.handleVisibilityChange);
    window.removeEventListener('focus', this.handlePageFocus);
  },
  watch: {
    publicUserID: {
    immediate: true,
    handler: function(newUserID, oldUserID) {
      if (oldUserID && newUserID !== oldUserID) {
       location.reload();
      }
    },
  },
},
methods: {
  handleVisibilityChange() {
      this.isVisible = !document.hidden;
      if (this.isVisible) {
        this.getPublic();
        this.publicUserID = localStorage.getItem('publicSignUpData')
        this.publicUniqueID = localStorage.getItem('public-uniqueId')
      }
    },
    handlePageFocus() {
      this.getPublic();
      this.publicUserID = localStorage.getItem('publicSignUpData')
      this.publicUniqueID = localStorage.getItem('public-uniqueId')
    },
  async getPublic() {
    this.publicUserID = localStorage.getItem('publicSignUpData')
    this.publicUniqueID = localStorage.getItem('public-uniqueId')
    const BASE_API_URL = process.env.VUE_APP_BASE_APIURL;
if(this.publicUserID){
      axios
    .get(`${BASE_API_URL}/public-user/${this.publicUserID}/one`)
    .then((response) => {
      const publicData = response.data
      if(publicData){
      if( publicData.publicunique_id != this.publicUniqueID ){
        window.location.href = "/";
      }
    }else{
      window.location.href = "/";
    }
    })
  }else{
    window.location.href = "/";
  }
}
}
}
</script>
<style>

.headerIcon {
  width: 50px;
  padding-left: 5px;
  padding-right: 5px;
}

.userMobileindexHeader {
  display: flex;
  justify-content: space-between;
  border: 1px solid #000;
  padding: 5px;
  align-items: center;
}
.text.w-100 {
  margin-bottom: 60px;
}

#page-content-wrapper {
  background-color: #f3f2ef;
      /* THIS OVERFLOW:HIDDEN IS JUST TEMPORARY FIX AND THIS IS FIXED FOR SCREEN SHAKING */
  overflow: hidden;
}

@media screen and (max-width:991px) {
  .common-mobile-icon{
  font-size: 30px;
  color: #000;
  padding-left: 5px;
  padding-right: 5px;
}
  #page-content-wrapper {
    background-color: #fff;
    overflow: hidden;
  }
}

</style>